<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        :dense="true"
        :name="`Tipo`"
        :type="`select`"
        :values="typesOptions"
        v-model="type"
      />

      <FormItem
        :dense="true"
        :name="`Identificativo`"
        :type="`text`"
        v-model="key"
      />

      <FormItem :dense="true" :name="`Valore`" :type="`text`" v-model="value" />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapGetters, mapMutations } from 'vuex'
import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields } = createHelpers({
  getterType: 'settings/getFiltersFields',
  mutationType: 'settings/SET_FILTER_FIELDS',
})

export default {
  name: 'SettingsSectionSearch',
  components: {
    BaseSectionSearch,
    FormItem,
  },
  computed: {
    ...mapGetters('settings', ['typesOptions']),
    ...mapFields([
      'default_value',
      'description',
      'key',
      'order',
      'section',
      'ttl',
      'type',
      'updated_at',
      'value',
    ]),
  },
  methods: {
    ...mapMutations('settings', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
